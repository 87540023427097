import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

class Partner extends Component {

  partnerSubmit = (e) => {
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <Header />
        <PartnerContent />
        <Footer />
      </div>
    );
  }
}

function PartnerContent() {
  return (
    <div>
      <div className="partnerImgHolder">
        <div className="container">
          <form onSubmit="">
            <ul className="partnerForm">
              <li className="partnerTitle"><h2>Lets Join hands togeather</h2>
                <p>Let's know your details and our expert will assist you soon.</p></li>
              <li>
                <input type="text" placeholder="Your Name" className="partnerContact" id="name" />
              </li>
              <li>
                <input
                  type="text"
                  placeholder="Your Phone No."
                  className="partnerContact"
                  id="phoneno"
                />
              </li>
              <li>
                <input
                  type="text"
                  placeholder="Email"
                  className="partnerContact"
                  id="email"
                />
              </li>
              <li>
                <textarea
                  type="text"
                  placeholder="Address"
                  className="partnerContact"
                  id="address"
                />
              </li>
              <li>
                <input type="submit" className="enquire" value="GET IN TOUCH" />
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Partner;
