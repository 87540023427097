import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Partner from "./components/pages/Partner";
import Services from "./components/pages/Services";
import AboutUs from "./components/pages/AboutUs";
import Fourofour from "./components/pages/Fourofour";
import Terms from "./components/pages/Terms";
import Pricing from "./components/pages/Pricing";
import Contact from "./components/pages/Contact";

class Routing extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/partner-with-us" exact component={Partner} />
          <Route path="/service/:service/:subservice?/" component={Services} />
          <Route path="/about-us/" component={AboutUs} />
          <Route path="/terms/" component={Terms} />
          <Route path="/pricing/" component={Pricing} />
          <Route path="/contact/" component={Contact} />
          <Route component={Fourofour} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routing;
