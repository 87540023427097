import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import SearchSection from '../SearchSection';

class Home extends Component {

  constructor() {
    super();
    this.state = {
      pageTitle: "Askain | Book Home / Restaurants services online: Trusted Professionals for maintenance and residential repair"
    };

    document.title = this.state.pageTitle;
  }

  iconsClick() {
    console.log(3);
  }

  componentDidMount() {
    let screenWidth = window.screen.availWidth / 170;
    screenWidth = parseInt(screenWidth);
    window.$(".repair").owlCarousel({
      items: screenWidth < 5 ? screenWidth : 5,
      margin: 10,
      dots: false
    });
    window.$(".maintenance").owlCarousel({
      items: screenWidth < 5 ? screenWidth : 5,
      margin: 10,
      dots: false
    });
    window.$(".careDesign").owlCarousel({
      items: screenWidth < 5 ? screenWidth : 5,
      margin: 10,
      dots: false
    });

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    function showPosition(position) {
      if (!localStorage.location) {
        localStorage.setItem("location", JSON.stringify({ "Latitude: ": position.coords.latitude, "Longitude: ": position.coords.longitude }));
      }
    }
    getLocation();
  }

  render() {
    return (
      <div>
        <Header />
        <SearchSection />
        <Icons />
        <WhyUS />
        <Carosals />
        <Footer />
      </div>
    );
  }
}

function WhyUS() {
  return (
    <div className="whyUsContainer">

      <h2>Why Choose Us?</h2>
      <ul className="whyUSContent">
        <li style={{ borderBottom: "5px solid #4c4b85" }}>
          <img src="/images/icons/003-worker.svg" className="whyUSicon" alt="" />
          <strong>Verified professionals</strong>
          <p>All our professionals are background checked and verified to ensure your complete safety.</p>
        </li>
        <li style={{ borderBottom: "5px solid #c0c815" }}>
          <img src="/images/icons/001-rate.svg" className="whyUSicon" alt="" />
          <strong>100% Satisfaction guaranteed</strong>
          <p>We hire only the most qualified professionals to ensure high quality services, such that your satisfaction levels are always at a 100%.</p></li>
        <li style={{ borderBottom: "5px solid #d0cdcd" }}>
          <img src="/images/icons/002-time.svg" className="whyUSicon" alt="" />
          <strong>On Time Arrival</strong>
          <p>We ensure our professionals will arrive at your doorstep on time, every time.</p></li>
      </ul>
    </div>
  );
}


class Icons extends React.Component {

  constructor() {
    super();
  }

  iconsClick = (e) => {
    console.log(e.currentTarget);
    let url = e.currentTarget.getAttribute("link");

  }

  render() {
    return (
      <div className="iconHolder">
        <ul className="services">
          <li>
            <img
              src="/images/icons/114735.svg"
              alt=""
            />
            <span>AC Repair</span>
          </li>
          <li>
            <img
              src="/images/icons/1186339.svg"
              alt=""
            />
            <span>Electrical</span>
          </li>
          <li>
            <img
              src="/images/icons/349385.svg"
              alt=""
            />
            <span>Electronics</span>
          </li>
          <li>
            <img
              src="/images/icons/578025.svg"
              alt=""
            />
            <span>Home Cleaning </span>
          </li>
          <li>
            <img
              src="/images/icons/1249641.svg"
              alt=""
            />
            <span>Microwave</span>
          </li>
          <li>
            <img src="images/icons/pest-observation.svg" alt="" />
            <span>Pest Control </span>
          </li>
          <li>
            <img src="/images/icons/194483.svg" alt="" />
            <span>Bathroom</span>
          </li>
          <li>
            <img
              src="/images/icons/1010398.svg"
              alt=""
            />
            <span>Sofa</span>
          </li>
          <li>
            <img
              src="/images/icons/701780.svg"
              alt=""
            />
            <span>Kitchen</span>
          </li>
        </ul>
      </div >
    );
  }
}

function Carosals() {
  return (
    <ul className="service-carousel">
      <li>
        <h2>Repair</h2>
        <div className="owl-carousel repair">
          <div>
            <Link to="/service/repair/air-conditioner"><img src="images/ac-service.jpg" alt="" />
              <p>AC Repair and Services</p>
            </Link>    </div>
          <div>
            <Link to="/service/repair/ro-water-purifier"><img src="images/ro-repair.jpg" alt="" />
              <p>RO Water Purifier Repair</p>
            </Link>    </div>
          <div>
            <Link to="/service/repair/washing-machine"><img src="images/washing-machine.jpg" alt="" />
              <p>Washing Machine</p>
            </Link>   </div>
          <div>
            <Link to="/service/repair/refrigerator"><img src="images/fridge-repair.jpg" alt="" />
              <p>Refrigerator</p>
            </Link>  </div>
          <div>
            <Link to="/service/repair/microwave"><img src="images/microwave-repair.jpg" alt="" />
              <p>Microwave Repair</p>
            </Link>    </div>
        </div>
      </li>

      <li>
        <h2>Maintenance</h2>
        <div className="owl-carousel maintenance">
          <div>
            <Link to="/service/maintenance/plumber"><img src="images/plumber.jpg" alt="" />
              <p>Plumbers</p>
            </Link>  </div>
          <div>
            <Link to="/service/maintenance/electrician"><img src="images/electricians.jpg" alt="" />
              <p>Electricians</p>
            </Link>  </div>
          <div>
            <Link to="/service/maintenance/carpenter"><img src="images/carpenter.jpg" alt="" />
              <p>Carpenters</p>
            </Link>  </div>
          <div>
            <Link to="/service/maintenance/pest-control"><img src="images/pest-control.jpg" alt="" />
              <p>Pest Control</p>
            </Link>  </div>
          <div>
            <Link to="/service/maintenance/home-deep-cleaning"><img src="images/deep-cleaning-home.jpg" alt="" />
              <p>Home Deep Cleaning</p>
            </Link>    </div>
          <div>
            <Link to="/service/maintenance/hood-chimney"><img src="images/hood-chimnie.jpg" alt="" />
              <p>Hood and Chimney</p>
            </Link>    </div>
        </div>
      </li>

      <li>
        <h2>Care & Design</h2>
        <div className="owl-carousel careDesign">
          <div>
            <Link to="/service/care/packers-movers"><img src="images/packers-movers.jpg" alt="" />
              <p>Packers & Movers</p>
            </Link>    </div>
          <div>
            <Link to="/service/care/house-painter"><img src="images/house-painters.jpeg" alt="" />
              <p>House Painters</p>
            </Link>  </div>
          <div>
            <Link to="/service/care/interior-designer"><img src="images/interior-designer.jpg" alt="" />
              <p>Interior Designers</p>
            </Link>    </div>
          <div>
            <Link to="/service/care/architecture"><img src="images/building-arcticture.jpg" alt="" />
              <p>Architects</p>
            </Link>  </div>
          <div>
            <Link to="/service/care/civil-contractors"><img src="images/civil-contractor.jpg" alt="" />
              <p>Civil Contractors</p>
            </Link>    </div>
        </div>
      </li>
    </ul>
  );
}

export default Home;
