import React, { Component } from "react";
class Footer extends Component {

  render() {
    let origin = window.location.origin;
    return (
      <footer>
        <div className="footerContentHolder">
          <div className="address desktop">
            <h3>Connect with us</h3>
            <p>#108 2nd & 3rd Floor,<br />Opposite Corner House,<br />27th Main Road, Sector 2 HSR Layout,<br />Bengaluru - 560102<br />Karnataka, India <br />
            </p>
          </div>

          <div className="company">
            <ul>
              <li className="heading">Company</li>
              <li><a href="/about-us">About Us</a></li>
              <li><a href="#">FAQ's</a></li>
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/terms">Terms & Conditions</a></li>
            </ul>
          </div>

          <div className="service desktop">
            <ul>
              <li className="heading">Service</li>
              <li><a href="#">Repair</a></li>
              <li><a href="#">Maintenance</a></li>
              <li><a href="#">Care & Design</a></li>
            </ul>
          </div>

          <div className="social">
            <div className="heading">Follow us</div>
            <a className="socialIcons" target="_new" href="https://www.facebook.com/Askain/"><img src={origin + "/images/icons/facebook.svg"} alt="" /></a>
            <a className="socialIcons" target="_new" href="https://www.instagram.com/Askain/"><img src={origin + "/images/icons/instagram.svg"} alt="" /></a>
            <a className="socialIcons" target="_new" href="#"><img src={origin + "/images/icons/twitter.svg"} alt="" /></a>
            <a className="socialIcons" target="_new" href="#"><img src={origin + "/images/icons/linkedin.svg"} alt="" /></a>
          </div>
        </div>
        <div className="copyright">© 2014-18 Askain.</div>
      </footer>
    );
  }
}

export default Footer;
