import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

class Contact extends Component {
  constructor() {
    super();

    super();
    this.state = {
      pageTitle: "Askain | Contact us"
    };

    document.title = this.state.pageTitle;
  }

  ContactView() {
    return (

      <div className="contactContainer">
        <div className="pricingHolder">
          <div className="titleHolder">
            <h1>Contact Us</h1>
            <p>Feel free to contact us</p>
          </div>
          <p className="pricingNote">
            PH -7019636919/9886691090<br />
            Address- 2nd & 3rd Floor, #108, Opposite Corner House, 27th Main Road, Sector 2, HSR Layout, Sector 2, HSR Layout, Bengaluru, Karnataka 560102</p>



        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        {this.ContactView()}
        <Footer />
      </div>
    );
  }
}

export default Contact;
