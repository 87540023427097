import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ServiceJSONData from "./ServiceJsonData";
import { Route, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "firebase";
import "../../config";
class Services extends Component {
    constructor() {
        super();
        this.state = {
            pageTitle: "Askain | ",
            startDate: new Date(),
            selectedService: {
                customerName: false,
                customerMobileNo: false,
                selectedCatagory: false,
                selectedService: false,
                selectedServiceDate: false,
                isSubmitted: false,
                success: false,
                location: localStorage.location ? localStorage.getItem("location") : ""
            }
        };
    }

    selectedDate = (date) => {
        this.setState({
            startDate: date
        });
    }

    selectedService = (e) => {
        document.querySelectorAll(".subElements").forEach(function (a) {
            if (a.classList.contains("active")) {
                a.classList.remove("active")
            }
        });
        e.target.classList.add("active");
        let updated = this.state.selectedService;
        let selectedService = e.target.innerHTML;
        updated.selectedService = selectedService;
        this.setState({
            selectedService: updated
        }, () => {
            document.getElementById("customerService").value = this.state.selectedService.selectedCatagory + " - " + selectedService;
        });
    }

    handleSubmit = (event) => {



      
        let updated = this.state.selectedService;
        updated.selectedServiceDate = document.getElementById("customerSelectedServiceDate").value;
        updated.isSubmitted = true;
        let _that = this;


        if (this.state.selectedService.customerName && this.state.selectedService.customerMobileNo && this.state.selectedService.selectedCatagory && this.state.selectedService.selectedService) {
            this.setState({
                selectedService: updated
            }, () => {
                // console.log(this.state.selectedService);
            });

            let fetchDate = new Date();
            fetchDate = fetchDate.toString();
            firebase
            .database()
            .ref("queries")
            .push({
                    customerName: this.state.selectedService.customerName,
                    customerMobileNo: this.state.selectedService.customerMobileNo,
                    selectedCatagory: this.state.selectedService.selectedCatagory,
                    selectedService: this.state.selectedService.selectedService,
                    selectedServiceDate: this.state.selectedService.selectedServiceDate,
                    location: this.state.selectedService.location,
                    timeStamp: fetchDate
                }).then((response)=>{
                  let updated = this.state.selectedService;
                  updated.isSubmitted = false;
                    updated.success = true;
                    _that.setState({
                        selectedService: updated
                    });
                });

            // fetch('/api/saveCustomerData', {
            //     method: 'post',
            //     body: JSON.stringify({
            //         customerName: this.state.selectedService.customerName,
            //         customerMobileNo: this.state.selectedService.customerMobileNo,
            //         selectedCatagory: this.state.selectedService.selectedCatagory,
            //         selectedService: this.state.selectedService.selectedService,
            //         selectedServiceDate: this.state.selectedService.selectedServiceDate,
            //         location: this.state.selectedService.location,
            //         timeStamp: fetchDate
            //     }),
            //     headers: { 'Content-type': 'application/json' }
            // }).then((response) => {

            //     let updated = this.state.selectedService;

            //     if (response.status !== 200) {
            //         console.log('Looks like there was a problem. Status Code: ' + response.status);
            //         updated.isSubmitted = false;
            //         _that.setState({
            //             selectedService: updated
            //         });
            //         return;
            //     }

            //     response.json().then(function (data) {
            //         updated.isSubmitted = false;
            //         updated.success = true;
            //         _that.setState({
            //             selectedService: updated
            //         });
            //     });
            // }
            // ).catch((err) => {
            //     console.log('Fetch Error :-S', err);
            // });
        }
        event.preventDefault();
    }

    customerNameLog = (event) => {
        let val = event.target.value.trim();
        let updated = this.state.selectedService;
        let customerName = val;
        updated.customerName = customerName;
        this.setState({
            selectedService: updated
        });
    }

    customerMobileNumberLog = (event) => {
        let val = event.target.value.trim();
        let updated = this.state.selectedService;
        let customerMobileNo = val;
        updated.customerMobileNo = customerMobileNo;
        this.setState({
            selectedService: updated
        });
    }

    serviceData = ServiceJSONData;

    ProviderContent(data) {
        return (
            <div className="serviceContainer">
                <div className="serviceImageHolder"></div>
                <div className="serviceDataContainer">
                    <h1>{data.title}</h1>
                    <div className="serviceData">
                        <div className="first">
                            <ul className="serviceDateList">
                                {data.subElement.map((name, index) => {
                                    return <li className="subElements" onClick={this.selectedService} key={index}>{name}</li>;
                                })}
                            </ul>
                        </div>
                        <div className="second">
                            <div className="enquiryForm">
                                <form onSubmit={this.handleSubmit} method="post">
                                    <h3>I want to for the service </h3>
                                    <input type="text" name="customerName" autoComplete="off" className={this.state.selectedService.customerName ? '' : 'fieldError'} onBlur={this.customerNameLog} id="customerName" placeholder="Your Name" />
                                    <input type="text" name="customerMobileNo" autoComplete="off" className={this.state.selectedService.customerMobileNo ? '' : 'fieldError'} id="customerMobileNo" onBlur={this.customerMobileNumberLog} placeholder="Your Mobile No" />
                                    <input type="text" name="customerService" autoComplete="off" readOnly className={this.state.selectedService.selectedService ? '' : 'fieldError'} id="customerService" placeholder="Need Service" />
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.selectedDate}
                                        minDate={new Date()}
                                        className="serviceCalander"
                                        id="customerSelectedServiceDate"
                                        autoComplete="off"
                                    />
                                    <button type="submit" className="hire">HIRE</button>
                                </form>
                            </div>
                            <div className="spinner" style={this.state.selectedService.isSubmitted ? { display: "block" } : { display: "none" }}></div>
                            <div className="successMessage" style={this.state.selectedService.success ? { display: "flex" } : { display: "none" }}>

                                <p>Your request has been noted by us, our agent will call you soon.<br /><br />In the mean while checkout our other services
                                <br />
                                    <Link to="/">Home Page</Link>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }

    render() {
        let data = {};
        data.service = this.props.match.params.service;
        data.subservice = this.props.match.params.subservice;
        data.serviceData = this.serviceData;
        data.serviceData.forEach(function (d) {
            if (d.url === data.subservice) {
                data.title = d.service;
                data.subElement = (d.subService ? d.subService : []);
            }
        });

        let updated = this.state.selectedService;
        updated.selectedCatagory = data.title;
        this.setState((previousState) => {
            previousState.pageTitle += data.service + " | " + data.title;
            previousState.selectedService = updated;
        }, () => {
            document.title = this.state.pageTitle;
        });

        if (data.title) {
            return (
                <div>
                    <Header />
                    {this.ProviderContent(data)}
                    <Footer />
                </div>
            );
        } else {
            return <Redirect to="/page-not-found" />
        }

    }
}

export default Services;
