import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      pageTitle: "About Askain "
    }

    document.title = this.state.pageTitle;
  }
  render() {
    return (
      <div>
        <Header />
        <AboutContent />
        <Footer />
      </div>
    );
  }
}

function AboutContent() {
  return (
    <div className="aboutUsContainer">

      <div className="binder">
        <img className="desktop" src="/images/office.jpg" alt="" />
        <h1>About Us</h1>

        <p>Askain,
Bangalore based E-commerce Market place for Home and Professional Services. Have you been worried about hiring a technician for your repair and services, here we are with a helping hand to all your worries,Book a service and our professional will be at your Door step,We provide housekeeping services which consist of Plumbers, Electricians, Carpenters, Cleaning and Pest Control. We also provide personal services like beauty, spa, mobile and other appliance repairs etc. Be it getting a plumbing job done, improving your fitness through yoga, learning to play the guitar, decorating your home or getting candid photos of your wedding clicked, we are a sure shot destination for your service needs.
      </p>
      </div>
    </div>
  );
}

export default AboutUs;
