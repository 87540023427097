import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";



class Fourofour extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Header />
                <Content />
                <Footer />
            </div>
        );
    }
}

class Content extends Component {

    containerStyle = {
        display: 'flex',
        height: '100vh',
        background: '#f5f5f5',
        width: "100vw"
    };

    contentStyle = {
        margin: "auto",
        fontSize: "1.5em",
        color: "#2e3437"
    };

    span = {
        marginRight: "10px",
        borderRight: "1px solid #2d3436",
        padding: "10px",
        fontWeight: "bold"
    };

    linkholder = {
        width: "100%",
        textAlign: "center"
    };

    backtoHome = {
        display: "inline-block",
        padding: "10px",
        fontSize: "15px",
        background: "#303437",
        color: "#ffffff",
        marginTop: "25px"
    };


    render() {
        // console.log(this.props.data);
        return (
            <div className="container" style={this.containerStyle}>
                <div style={this.contentStyle}>
                    <span style={this.span}>404</span>
                    The page you requested was not found.
                    <div style={this.linkholder}>
                        <Link style={this.backtoHome} to="/">
                            Back to home
          </Link>
                    </div>
                </div>


            </div>
        );
    }
}


export default Fourofour;
