import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    let origin = window.location.origin + "/images/Askain-logo.svg";
    return (
      <div>
        <header>
          <h1 className="logo">
            <Link className="logoLink" to="/">
              <img src={origin} />
            </Link>
          </h1>

          <Link className="partner desktop" to="/partner-with-us">
            Partner with us
          </Link>
          <Link className="email desktop" to="/partner-with-us">
            Get a Free Trial
          </Link>
        </header>
      </div>
    );
  }
}

export default Header;
