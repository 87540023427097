import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

class Terms extends Component {

  constructor() {
    super();
    this.state = {
      pageTitle: "Terms and Conditions - Askain "
    }

    document.title = this.state.pageTitle;
  }

  render() {
    return (
      <div>
        <Header />
        <AboutContent />
        <Footer />
      </div>
    );
  }
}

function AboutContent() {
  return (
    <div className="terms">

      <div className="binder">
        <h1>Welcome to our Privacy Policy</h1>
        <h3>Your privacy is critically important to us.</h3>
        Royal Lala Groups is located at:<br />
        <address>
          Royal Lala Groups<br />2nd & 3rd Floor, #108, Opposite Corner House, 27th Main Road, Sector 2, HSR Layout,<br />7019636919			</address>

        <p>It is Royal Lala Groups’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="http://www.Askain.com">www.Askain.com</a> (hereinafter, "us", "we", or "www.Askain.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
        <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

        <h2>Website Visitors</h2>
        <p>Like most website operators, Royal Lala Groups collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Royal Lala Groups’s purpose in collecting non-personally identifying information is to better understand how Royal Lala Groups’s visitors use its website. From time to time, Royal Lala Groups may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
        <p>Royal Lala Groups also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://www.Askain.com blog posts. Royal Lala Groups only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

        <h2>Gathering of Personally-Identifying Information</h2>
        <p>Certain visitors to Royal Lala Groups’s websites choose to interact with Royal Lala Groups in ways that require Royal Lala Groups to gather personally-identifying information. The amount and type of information that Royal Lala Groups gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at http://www.Askain.com to provide a username and email address.</p>

        <h2>Security</h2>
        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

        <h2>Advertisements</h2>
        <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Royal Lala Groups and does not cover the use of cookies by any advertisers.</p>


        <h2>Links To External Sites</h2>
        <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
        <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>


        <h2>Protection of Certain Personally-Identifying Information</h2>
        <p>Royal Lala Groups discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Royal Lala Groups’s behalf or to provide services available at Royal Lala Groups’s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Royal Lala Groups’s website, you consent to the transfer of such information to them. Royal Lala Groups will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Royal Lala Groups discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Royal Lala Groups believes in good faith that disclosure is reasonably necessary to protect the property or rights of Royal Lala Groups, third parties or the public at large.</p>
        <p>If you are a registered user of http://www.Askain.com and have supplied your email address, Royal Lala Groups may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with Royal Lala Groups and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Royal Lala Groups takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>

        <h2>Aggregated Statistics</h2>
        <p>Royal Lala Groups may collect statistics about the behavior of visitors to its website. Royal Lala Groups may display this information publicly or provide it to others. However, Royal Lala Groups does not disclose your personally-identifying information.</p>


        <h2>Cookies</h2>
        <p>To enrich and perfect your online experience, Royal Lala Groups uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
        <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Royal Lala Groups uses cookies to help Royal Lala Groups identify and track visitors, their usage of http://www.Askain.com, and their website access preferences. Royal Lala Groups visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Royal Lala Groups’s websites, with the drawback that certain features of Royal Lala Groups’s websites may not function properly without the aid of cookies.</p>
        <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Royal Lala Groups's use of cookies.</p>

        <h2>E-commerce</h2>
        <p>Those who engage in transactions with Royal Lala Groups – by purchasing Royal Lala Groups's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Royal Lala Groups collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with Royal Lala Groups. Royal Lala Groups does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p>


        <h2>Privacy Policy Changes</h2>
        <p>Although most changes are likely to be minor, Royal Lala Groups may change its Privacy Policy from time to time, and in Royal Lala Groups’s sole discretion. Royal Lala Groups encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>



        <h2>PACKAGES TERMS AND USES</h2>
        <p>Visits :-I In this customer can avail Free visits as per packages, If He/She want to avail more then that it will be paid as per request ,
(Ex:-If he calls for Ac repair visit he can ask us about other queries also ).

Services:- In this customer can avail Free services as per packages, If He/She want to avail more then that it will be paid as per request ,
(Ex:-If he calls us for deep freezer service he can get other service done in this which is there in package ).In this if there is any minor repair we will tell the partner to raise a complaint for Minor repair

Minor Repair :-In this customer can avail Free minor Repair as per packages, If He/She want to avail more then that it will be paid as per request ,
(Ex:-Minor repair like wire connections,AC blockage,Filter blockage).</p>
      </div>
    </div>
  );
}

export default Terms;
