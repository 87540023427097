import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCOooBCSJkmurupUM3gQAitKTbnF3QjXuY",
  authDomain: "askain-944bf.firebaseapp.com",
  databaseURL: "https://askain-944bf.firebaseio.com",
  projectId: "askain-944bf",
  storageBucket: "askain-944bf.appspot.com",
  messagingSenderId: "616097707912",
  appId: "1:616097707912:web:0c748c32a08e6187af2c3d",
  measurementId: "G-YRY38L52WM"
};

firebase.initializeApp(firebaseConfig);
