import React, { Component } from "react";
import { Redirect } from "react-router";
import ServiceJSONData from "./pages/ServiceJsonData";

class SearchSection extends Component {
    constructor() {
        super();
        this.state = {
            serviceData: ServiceJSONData,
            quariedData: [],
            recentSearchDisplay: true,
            searchableData: {},
        }
    }

    componentDidMount() {
        let searchable = [];
        this.state.serviceData.forEach((data, index) => {
            searchable.push(data);
            data.subService.forEach((d, i) => {
                searchable.push({ "service": d + " - " + data.service, "fullurl": data.fullurl });
            });
        });
        this.state.searchingData = this.setState({
            searchableData: searchable
        });
    }

    handleSearchResultClick = (e) => {

        try {

            window.location.href = e.target.attributes.redirecturl.value
        } catch (e) {
            console.log(e);
        }

    }


    handleLoginKeyUp = (e) => {
        let displayLimit = 10;
        let term = e.target.value.toLowerCase();
        let filteredResult = this.state.searchableData.filter(function (data) {
            if (data.service.toLowerCase().match(term)) {
                return data;
            }
        });

        filteredResult = filteredResult.splice(0, displayLimit);

        if (term.trim() == "") {
            this.setState({
                quariedData: [],
                recentSearchDisplay: true
            });
        } else {
            this.setState({
                quariedData: filteredResult,
                recentSearchDisplay: false
            });
        }
        console.log(this.state);
    }

    render() {
        return (
            <section>
                <div className="searchSection">
                    <h1 className="serviceTag">Your Service Expert in Bangalore</h1>
                    <h2 className="serviceTagLine">
                        Get instant access to reliable and affordable services
                    </h2>
                    <input type="text" placeholder="Search for a service" autoComplete="off" onKeyUp={this.handleLoginKeyUp} id="search" />
                    <ul className="searchResult">
                        {this.state.quariedData.map((key, index) => {
                            return <li onClick={this.handleSearchResultClick} redirecturl={key.fullurl} key={index}>{key.service}</li>
                        })}
                    </ul>
                    <h3 className="searchHint">
                        E.g. Air Conditioner Repair, Plumber, Furniture Installation
                </h3>
                </div>
            </section>
        );
    }
}

export default SearchSection;
