import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";

class Pricing extends Component {
  constructor() {
    super();

    super();
    this.state = {
      pageTitle: "Askain | Our Pricing"
    };

    document.title = this.state.pageTitle;
  }

  pricingView() {
    return (

      <div className="pricingContainer">
        <div className="pricingHolder">
          <div className="titleHolder">
            <h1>Our Pricing</h1>
            <p>Have got tired of being charged for all your maintenance,repairs and service charges. We have got something for you all, get registered with us and get free from all charges of repairs and maintenance cost. We are all surrounded in Bangalore for commercial and residential. Make your self comfort trusting Askain</p>
          </div>

          <div className="plansTableHolder">
            <div className="plansContainerFlex">
              <div className="plans">
                <div className="planTitle">
                  <h5>STANDARD</h5>
                </div>
                <h6>No Visiting Charges</h6>
                <h6>No Service Charges</h6>
                <h6>No Minor Repair Charges</h6>
                <h6>2+2+2=6 for 3 months</h6>
                <h4>999/-</h4>
                <a href="#">BUY NOW</a>
              </div>

              <div className="plans">
                <div className="planTitle">
                  <h5>CLASSIC</h5>
                </div>
                <h6>No Visiting Charges</h6>
                <h6>No Service Charges</h6>
                <h6>No Minor Repair Charges</h6>
                <h6>3+3+3=9 for 6 months</h6>
                <h4>1499/-</h4>
                <a href="#">BUY NOW</a>
              </div>

              <div className="plans">
                <div className="planTitle">
                  <h5>STANDARD</h5>
                </div>
                <h6>No Visiting Charges</h6>
                <h6>No Service Charges</h6>
                <h6>No Minor Repair Charges</h6>
                <h6>5+5+5=15 for 12 months</h6>
                <h4>2999/-</h4>
                <a href="#">BUY NOW</a>
              </div>
            </div>
          </div>
          <p className="pricingNote">*Package Serves Plumbing,Electrical & Appliances.</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        {this.pricingView()}
        <Footer />
      </div>
    );
  }
}

export default Pricing;
