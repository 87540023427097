export default [{
    service: "Air Conditioner Repair",
    url: "air-conditioner",
    fullurl: "/service/repair/air-conditioner",
    subService: ["Window", "Split", "Cassette"]
}, {
    service: "Washing Machine Repair",
    url: "washing-machine",
    fullurl: "/service/repair/washing-machine",
    subService: ["Front load", "Top Load", "Im not sure"]
}, {
    service: "Microwave",
    url: "microwave",
    fullurl: "/service/repair/microwave",
    subService: ["Repair"]
}, {
    service: "RO Repair Services",
    url: "ro-water-purifier",
    fullurl: "/service/repair/ro-water-purifier",
    subService: ["Repair / Servicing", "Installation / Un-Installation"]
}, {
    service: "Refrigerator Repair Services",
    url: "refrigerator",
    fullurl: "/service/repair/refrigerator",
    subService: ["Single door", "Double Door", "Triple door", "Other"]
}, {
    service: "Plumbers",
    url: "plumber",
    fullurl: "/service/maintenance/plumber",
    subService: ["Repairs or Fixes", "Standard installation", "Blocks and Leakages", "Toilet and sanitary work", "All Services"]
}, {
    service: "Carpenters",
    url: "carpenter",
    fullurl: "/service/maintenance/carpenter",
    subService: ["Carpentery services", "Furniture Installation and Asssembly", "All services"]
}, {
    service: "Pest Control",
    url: "pest-control",
    fullurl: "/service/maintenance/pest-control",
    subService: ["General Pest Control", "Malaria Pest Control", "Bed Bugs Pest Control", "All services"]
}, {
    service: "Home Deep Cleaning",
    url: "home-deep-cleaning",
    fullurl: "/service/maintenance/home-deep-cleaning",
    subService: ["Villa Deep Cleaning", "Apartment Deep Cleaning", "I'm not sure"]
}, {
    service: "Interiors Design",
    url: "interior-designer",
    fullurl: "/service/care/interior-designer",
    subService: ["Bed Room", "Living Room", "Kitchen", "I'm not sure"]
}, {
    service: "Civil Contractors",
    url: "civil-contractors",
    fullurl: "/service/care/civil-contractors",
    subService: ["Residential building", "Commercial", "Residentail Apartment/ Villa", "Commercial estblishment (Office / shop)"]
}, {
    service: "Architecture",
    url: "architecture",
    fullurl: "/service/care/architecture",
    subService: ["Restaurants", "Commercial", "Residentail Plots"]
}, {
    service: "Packers and Movers",
    url: "packers-movers",
    fullurl: "/service/care/packers-movers",
    subService: ["Corporate Shifting", "Household / Car / Bike Shifting", "Poineers in Domestic Shifting"]
}, {
    service: "Our Pricing",
    url: "pricing",
    fullurl: "/pricing",
    subService: ["Our Pricing", "Rate", "Charges"]
}, {
    service: "Electrical",
    url: "electrician",
    fullurl: "/service/maintenance/electrician",
    subService: ["Repairs", "New Installation"]
}, {
    service: "House Painter",
    url: "house-painter",
    fullurl: "/service/care/house-painter",
    subService: ["House", "Commercial Space"]
}];
